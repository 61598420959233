import React, { useState } from 'react';
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { ReactComponent as CalendarIcon } from 'assets/icons/input-icons/calendar.svg';

import { Input } from 'components/ui/Input';

import { CustomDatePickerHeader } from './components/ui/CustomDatePickerHeader';
import { monthSelectItems } from './constants/month-select-items.const';
import { START_YEAR } from './constants/start-year.const';
import { ICustomDatePickerProps } from './types/custom-datepicker-props.interface';
import { getIsoStringFromDate } from './utils/get-iso-string-from-date.util';
import { getSelectItemsFromYear } from './utils/get-select-items-from-year.util';

import 'react-datepicker/dist/react-datepicker.css';
import * as S from './CustomDatePicker.styled';

export const CustomDatePicker: React.FC<ICustomDatePickerProps> = ({
  currentDateIsoString,
  onDateChange,
  isDisabled = false,
  className,
  id,
  hasErrors = false,
}) => {
  const years = getSelectItemsFromYear(START_YEAR);
  const currentDate = currentDateIsoString ? new Date(currentDateIsoString) : null;
  const today = new Date();

  const [tempDate, setTempDate] = useState<Date | null>(currentDate);

  const handleDateChange = (newDate: Date | null) => {
    if (newDate && newDate > today) {
      newDate = today;
    }
    setTempDate(newDate);
  };

  const handleCalendarClose = () => {
    if (tempDate && tempDate > today) {
      setTempDate(today);
    }
    const newDateIsoString = tempDate ? getIsoStringFromDate(tempDate) : null;
    if (newDateIsoString && newDateIsoString !== currentDateIsoString) {
      onDateChange(newDateIsoString);
    }
  };

  const handleCurrentDateYearChange = (newFullYearString: string) => {
    if (tempDate) {
      const newDate = new Date(tempDate.setFullYear(Number(newFullYearString)));
      if (newDate > today) {
        newDate.setFullYear(today.getFullYear());
        newDate.setMonth(today.getMonth());
        newDate.setDate(today.getDate());
      }
      setTempDate(newDate);
    }
  };

  const handleCurrentDateMonthChange = (newMonthString: string) => {
    if (tempDate) {
      const newDate = new Date(tempDate.setMonth(Number(newMonthString)));
      if (newDate > today) {
        newDate.setMonth(today.getMonth());
        newDate.setDate(today.getDate());
      }
      setTempDate(newDate);
    }
  };

  return (
    <S.DatePickerContainer className={className}>
      <DatePicker
        showIcon
        icon={<CalendarIcon />}
        toggleCalendarOnIconClick
        selected={currentDate}
        onChange={handleDateChange}
        onCalendarClose={handleCalendarClose}
        customInput={<Input isDisabled={isDisabled} isReadOnly={true} hasErrors={hasErrors} />}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
        }: ReactDatePickerCustomHeaderProps) => (
          <CustomDatePickerHeader
            years={years}
            months={monthSelectItems}
            currentYear={String(date.getFullYear())}
            currentMonth={String(date.getMonth())}
            handleYearChange={(newValue) => {
              changeYear(Number(newValue));
              handleCurrentDateYearChange(newValue);
            }}
            handleMonthChange={(newValue) => {
              changeMonth(Number(newValue));
              handleCurrentDateMonthChange(newValue);
            }}
          />
        )}
        maxDate={today}
        minDate={new Date(START_YEAR, Number(monthSelectItems[0].value))}
        calendarStartDay={1}
        popperPlacement="bottom-start"
        showPopperArrow={false}
        dateFormat="d MMMM yyyy"
        disabled={isDisabled}
        id={id}
      />
    </S.DatePickerContainer>
  );
};
