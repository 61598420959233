import { useSelector } from 'react-redux';
import { InvoiceHeader } from 'pages/PagePurchase/components/ui/InvoiceHeader';
import { MinersHeader } from 'pages/PagePurchase/components/ui/MinersHeader';
import { IPurchaseHeaderElements } from 'pages/PagePurchase/types/purchase-header-elements.interface';
import { selectInvoicePdfLinks } from 'store/Invoice-pdf-links-reducer/Invoice-pdf-links.selectors';
import { selectInvoiceData } from 'store/invoice-reducer/invoice.selectors';
import { selectPaymentOrdersIds } from 'store/payment-reducer/payment.selectors';
import { selectPurchaseStep } from 'store/purchase-reducer/purchase.selectors';

export const usePurchaseHeader = (): IPurchaseHeaderElements => {
  let headerContent = null;
  const purchaseStep = useSelector(selectPurchaseStep);
  const invoiceData = useSelector(selectInvoiceData);
  const pdfLinks: string[] = useSelector(selectInvoicePdfLinks);
  const paymentOrdersIds = useSelector(selectPaymentOrdersIds);
  const links = paymentOrdersIds ? pdfLinks.slice(-paymentOrdersIds.length) : pdfLinks;
  const handlePdfClick = () => links.forEach((url) => window.open(url, '_blank'));

  switch (purchaseStep) {
    case 'buying':
      headerContent = <MinersHeader />;
      break;
    case 'checkout':
    case 'paid-success':
      headerContent = (
        <InvoiceHeader
          step={purchaseStep}
          invoiceNumber={invoiceData?.id}
          invoiceDate={invoiceData?.createdAt}
          handlePdfClick={handlePdfClick}
        />
      );
  }

  return {
    headerContent,
  };
};
