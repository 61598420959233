import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IReferralStatsMapped } from 'store/api/cuverse-api/affiliate/types/affiliate-referral-stats-response.interface';
import { IReferralLinkMapped } from 'store/api/cuverse-api/affiliate/types/referral-links-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { EmptyReferralLink } from './constants/empty-referral-link.const';
import {
  fetchReferralLinksThunkAction,
  fetchReferralStatsThunkAction,
} from './affiliate.thunk-actions';

export interface IAffiliateState {
  referralStats: IReferralStatsMapped;
  referralStatsStatus: TFetchStatus;
  referralLink: IReferralLinkMapped;
  referralLinkFetchStatus: TFetchStatus;
  debugError: string | null;
}

const initialState: IAffiliateState = {
  referralStats: {
    totalReferrals: 0,
    totalProfit: 0,
    referralCommission: 0,
  },
  referralStatsStatus: 'initial',
  referralLink: EmptyReferralLink,
  referralLinkFetchStatus: 'initial',
  debugError: null,
};

export const affiliateSlice = createSlice({
  name: 'affiliate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReferralStatsThunkAction.pending, (state) => {
        state.referralStatsStatus = 'pending';
        state.debugError = null;
      })
      .addCase(fetchReferralStatsThunkAction.fulfilled, (state, action) => {
        state.referralStatsStatus = 'fulfilled';
        state.referralStats = action.payload;
      })
      .addCase(fetchReferralStatsThunkAction.rejected, (state, error) => {
        state.referralStatsStatus = 'rejected';
        state.debugError = JSON.stringify(error);
      })
      .addCase(fetchReferralLinksThunkAction.pending, (state) => {
        state.referralLink = EmptyReferralLink;
        state.referralLinkFetchStatus = 'pending';
        state.debugError = null;
      })
      .addCase(
        fetchReferralLinksThunkAction.fulfilled,
        (state, action: PayloadAction<IReferralLinkMapped>) => {
          state.referralLink = action.payload;
          state.referralLinkFetchStatus = 'fulfilled';
        },
      )
      .addCase(fetchReferralLinksThunkAction.rejected, (state, error) => {
        state.referralLinkFetchStatus = 'rejected';
        state.debugError = JSON.stringify(error);
      });
  },
});

export const affiliateReducer = affiliateSlice.reducer;
