import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectProfile } from 'store/profile-reducer/profile.selectors';
import { requestProfileDataThunkAction } from 'store/profile-reducer/profile.thunk-actions';
import { useAppDispatch } from 'store/store';
import { selectVerificationData } from 'store/verification-reducer/verification.selectors';
import { fetchVerificationThunkAction } from 'store/verification-reducer/verification.thunk-actions';

import { getVerificationPageTitle } from 'utils/common/get-verification-page-title.util';

import { SumsubWidget } from './components/ui/SumsubWidget';

import * as S from './PageVerification.styled';

export const PageVerification: React.FC = () => {
  const { verificationStatus } = useSelector(selectProfile);
  const title = getVerificationPageTitle(verificationStatus);
  const dispatch = useAppDispatch();
  const { applicantToken } = useSelector(selectVerificationData);

  useEffect(() => {
    void dispatch(requestProfileDataThunkAction());
    void dispatch(fetchVerificationThunkAction());
  }, [dispatch]);

  return (
    <>
      <S.VerificationPageHeader>
        <S.VerificationPageTitle>{title}</S.VerificationPageTitle>
      </S.VerificationPageHeader>
      <S.SumsubWrapper>
        {applicantToken && (
          <SumsubWidget
            applicantToken={applicantToken}
            accessTokenExpirationHandler={() => void dispatch(fetchVerificationThunkAction())}
          />
        )}
      </S.SumsubWrapper>
    </>
  );
};
