import { EVerificationStatus } from 'store/api/cuverse-api/profile/types/profile-response.interface';

/**
 * Not verified status
 * @param verificationStatus status of verification
 * @returns boolean value
 */
export const getIsNotVerifiedStatus = (verificationStatus: EVerificationStatus): boolean => {
  return (
    verificationStatus !== EVerificationStatus.FinalVerified &&
    verificationStatus !== EVerificationStatus.Verified &&
    verificationStatus !== EVerificationStatus.FinalVerificationChecking
  );
};
