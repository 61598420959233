import { AxiosResponse } from 'axios';
import { cuverseApi } from 'store/api/cuverse-api/cuverse.api';

import { IReferralStatsResponse } from './types/affiliate-referral-stats-response.interface';
import { IReferralLinksResponse } from './types/referral-links-response.interface';
import { AffiliateRoute } from './affiliate.route';

export const requestReferralStats = async (): Promise<IReferralStatsResponse> =>
  await cuverseApi.get(AffiliateRoute.ReferralStats()).then((res) => res?.data);

export const requestReferralLinks = async (): Promise<IReferralLinksResponse> =>
  cuverseApi
    .get<
      IReferralLinksResponse,
      AxiosResponse<IReferralLinksResponse>
    >(AffiliateRoute.ReferralLinks())
    .then((res) => {
      return res?.data;
    });
