import { verificationStatuses } from 'pages/PageVerification/constants/constants';
import { EVerificationStatus } from 'store/api/cuverse-api/profile/types/profile-response.interface';

/**
 * Verification page title
 * @param verificationStatus status of verification
 * @returns string title
 */
export const getVerificationPageTitle = (verificationStatus: EVerificationStatus): string => {
  return verificationStatus === EVerificationStatus.Verified ||
    verificationStatus === EVerificationStatus.FinalVerificationChecking
    ? verificationStatuses.fullVerification.statusText
    : verificationStatuses.basicVerification.statusText;
};
