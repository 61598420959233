import { EVerificationStatus } from 'store/api/cuverse-api/profile/types/profile-response.interface';

/**
 * full verified status
 * @param verificationStatus status of verification
 * @returns boolean value
 */
export const getIsFullVerificationStatus = (verificationStatus: EVerificationStatus): boolean => {
  return verificationStatus === EVerificationStatus.FinalVerified;
};
