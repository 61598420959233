import React from 'react';
import { ReactComponent as BasicVerifiedIcon } from 'assets/icons/verify-icons/basic-verification-success-icon.svg';
import { ReactComponent as FullVerifiedIcon } from 'assets/icons/verify-icons/full-verification-success-icon.svg';
import { ReactComponent as NotVerifiedIcon } from 'assets/icons/verify-icons/profile-status-icon.svg';
import { IVerificationStatusText } from 'pages/PageVerification/types/types';
import { EVerificationStatus } from 'store/api/cuverse-api/profile/types/profile-response.interface';

/**
 * Profile verification status
 * @param verificationStatus verification status
 * @returns an object of text and icon
 */
export const getVerificationStatusTag = (
  verificationStatus: EVerificationStatus,
): IVerificationStatusText => {
  let status;
  switch (verificationStatus) {
    case EVerificationStatus.FinalVerified:
      status = {
        statusText: 'Full verification',
        icon: FullVerifiedIcon,
      };
      break;
    case EVerificationStatus.Verified:
    case EVerificationStatus.FinalVerificationChecking:
      status = {
        statusText: 'Basic verification',
        icon: BasicVerifiedIcon,
      };
      break;
    default:
      status = {
        statusText: 'Not verified',
        icon: NotVerifiedIcon,
      };
      break;
  }
  return status;
};
