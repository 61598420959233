import { AxiosResponse } from 'axios';
import { ILoginRequestBody } from 'store/api/cuverse-api/auth/types/login-request-body.interface';
import { ILoginResponse } from 'store/api/cuverse-api/auth/types/login-response-interface';
import { cuverseApi } from 'store/api/cuverse-api/cuverse.api';

import { IConfirm2FARequestBody } from './types/confirm-2fa-request-body.interface';
import { IConfirm2FAResponseBody } from './types/confirm-2fa-response-body.interface';
import { IDisable2FARequestBody } from './types/disable-2fa-request-body.interface';
import { IDisable2FAResponseBody } from './types/disable-2fa-response-body.interface';
import { IEmailVerifyRequestBody } from './types/email-verify-request-body.interface';
import { IEmailVerifyResponseBody } from './types/email-verify-response-body.interface';
import { IEnable2FAResponseBody } from './types/enable-2fa-response-body.interface';
import { IOtpVerifyRequestBody } from './types/otp-verify-request-body.interface';
import { IOtpVerifyResponseBody } from './types/otp-verify-response-body.interface';
import { IPasswordResetRequestBody } from './types/password-reset-request-body.interface';
import { IRefreshTokenResponse } from './types/refresh-token-response.interface';
import { IRegistrationRequestBody } from './types/registration-request-body.interface';
import { IRegistrationResponseBody } from './types/registration-response-body.interface';
import { IResendEmailRequestBody } from './types/resend-email-request-body.interface';
import { IResendEmailResponseBody } from './types/resend-email-response-body.interface';
import { AuthRoute } from './auth.route';

export const requestLogin = async (body: ILoginRequestBody): Promise<ILoginResponse> =>
  cuverseApi
    .post<ILoginResponse, AxiosResponse<ILoginResponse>, ILoginRequestBody>(AuthRoute.Login(), body)
    .then((res) => res?.data);

export const requestTokenProlongation = async (): Promise<IRefreshTokenResponse> =>
  cuverseApi
    .post<IRefreshTokenResponse, AxiosResponse<IRefreshTokenResponse>>(AuthRoute.RefreshToken())
    .then((res) => res?.data);

export const requestLogout = async (): Promise<void> =>
  cuverseApi.post<void>(AuthRoute.Logout()).then(() => undefined);

export const requestRegistration = async (
  body: IRegistrationRequestBody,
): Promise<IRegistrationResponseBody> =>
  cuverseApi
    .post<
      IRegistrationResponseBody,
      AxiosResponse<IRegistrationResponseBody>,
      IRegistrationRequestBody
    >(AuthRoute.Register(), body)
    .then((res) => res?.data);

export const requestEmailVerify = async (
  body: IEmailVerifyRequestBody,
): Promise<IEmailVerifyResponseBody> => {
  return cuverseApi
    .patch<
      IEmailVerifyResponseBody,
      AxiosResponse<IEmailVerifyResponseBody>,
      IEmailVerifyRequestBody
    >(AuthRoute.EmailVerify(), body)
    .then((res) => res?.data);
};

export const requestEmailResend = async (
  body: IResendEmailRequestBody,
): Promise<IResendEmailResponseBody> =>
  cuverseApi
    .patch<
      IResendEmailResponseBody,
      AxiosResponse<IResendEmailResponseBody>,
      IResendEmailRequestBody
    >(AuthRoute.EmailResend(), body)
    .then((res) => res?.data);

export const requestPasswordChangeCode = async (email: string): Promise<void> =>
  cuverseApi.post<void>(AuthRoute.PasswordChangeCode(), { email }).then(() => undefined);

export const requestOtpVerification = async (
  body: IOtpVerifyRequestBody,
): Promise<IOtpVerifyResponseBody> =>
  cuverseApi
    .post<
      IOtpVerifyResponseBody,
      AxiosResponse<IOtpVerifyResponseBody>,
      IOtpVerifyRequestBody
    >(AuthRoute.OtpVerify(), body)
    .then((res) => res?.data);

export const requestPasswordReset = async (body: IPasswordResetRequestBody): Promise<void> =>
  cuverseApi
    .post<void, AxiosResponse<void>, IPasswordResetRequestBody>(AuthRoute.PasswordReset(), body)
    .then(() => undefined);

export const requestEnable2FA = async (): Promise<IEnable2FAResponseBody> =>
  cuverseApi
    .get<IEnable2FAResponseBody, AxiosResponse<IEnable2FAResponseBody>>(AuthRoute.Enable2FA())
    .then((res) => res?.data);

export const requestConfirm2FA = async (
  body: IConfirm2FARequestBody,
): Promise<IConfirm2FAResponseBody> =>
  cuverseApi
    .patch<
      IConfirm2FAResponseBody,
      AxiosResponse<IConfirm2FAResponseBody>,
      IConfirm2FARequestBody
    >(AuthRoute.Confirm2FA(), body)
    .then((res) => res?.data);

export const requestDisable2FA = async (
  body: IDisable2FARequestBody,
): Promise<IDisable2FAResponseBody> =>
  cuverseApi
    .patch<
      IDisable2FAResponseBody,
      AxiosResponse<IDisable2FAResponseBody>,
      IDisable2FARequestBody
    >(AuthRoute.Disable2FA(), body)
    .then((res) => res?.data);

export const handle2faError = async function* ({
  body,
  onErrorCb,
  onSuccessCb,
}: {
  body: ILoginRequestBody;
  onSuccessCb: (token: string) => void;
  onErrorCb: () => void;
}): AsyncGenerator<string | undefined, void, string> {
  const otpCode = yield;

  await requestLogin({ ...body, auth_confirm_code: otpCode })
    .then(({ token }: ILoginResponse) => onSuccessCb(token))
    .catch(() => onErrorCb());
};
