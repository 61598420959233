import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { setUserAuthStatusAction } from 'store/auth-reducer/auth.reducer';
import {
  selectEmailVerifyFetchStatus,
  selectRegistrationFetchStatus,
} from 'store/auth-reducer/auth.selectors';
import {
  requestEmailResendThunkAction,
  requestEmailVerifyThunkAction,
  requestLogoutThunkAction,
  requestRegisterThunkAction,
} from 'store/auth-reducer/auth.thunk-actions';
import { useAppDispatch } from 'store/store';
import { StorageKey } from 'types/storage/storage-key.enum';
import { IAuthFormValues } from 'types/ui/AuthForm/auth-form-values.interface';
import { IOtpFormValues } from 'types/ui/OtpForm/otp-form-values.interface';

import { AuthForm } from 'components/ui/AuthForm';
import { AuthSuccess } from 'components/ui/AuthSuccess';
import { OtpForm } from 'components/ui/OtpForm';
import { gtmClickSendForm } from 'utils/gtmSender/gtmSender';
import { setEmailSendTime } from 'utils/storage/date-and-time/set-email-send-time.util';
import { deleteEmail } from 'utils/storage/email/delete-email.util';
import { getEmail } from 'utils/storage/email/get-email.util';
import { setEmail } from 'utils/storage/email/set-email.util';

import { useRegistrationStep } from './hooks/useRegistrationStep';
import { TRegistrationStep } from './types/registration-step.type';

export const PageRegistration: React.FC = () => {
  const dispatch = useAppDispatch();
  const emailVerifyFetchStatus = useSelector(selectEmailVerifyFetchStatus);
  const registrationFetchStatus = useSelector(selectRegistrationFetchStatus);

  const [registrationStep, setRegistrationStep] = useState<TRegistrationStep>('initial');

  useRegistrationStep(registrationStep, setRegistrationStep);

  const handleAuthFormSubmit = ({ email, password, agreement }: IAuthFormValues) => {
    const outerRoute = localStorage.getItem(StorageKey.OuterRoute);
    let affiliateCode = undefined;
    const hasAffiliateCode = Boolean(outerRoute?.match(/^\/r\/./)?.[0]);
    if (outerRoute && hasAffiliateCode) {
      affiliateCode = outerRoute.replace(/^\/r\//, '');
    }

    setEmail(email);
    void dispatch(
      requestRegisterThunkAction({
        email,
        password,
        confirm_password: password,
        user_agree: agreement,
        affiliate_code: affiliateCode,
      }),
    );
  };

  const handleOtpFormSubmit = ({ code }: IOtpFormValues) => {
    void dispatch(
      requestEmailVerifyThunkAction({
        email: getEmail(),
        verification_code: code,
        gtmClickSendForm,
      }),
    );
  };

  const handleResendOtp = () => {
    setEmailSendTime();
    void dispatch(requestEmailResendThunkAction({ email: getEmail() }));
  };

  const handleOtpBackClick = () => void dispatch(requestLogoutThunkAction());

  const handleSuccessContinue = () => {
    dispatch(setUserAuthStatusAction('authorized-verified'));
    deleteEmail();
  };

  switch (registrationStep) {
    case 'initial':
      return (
        <AuthForm
          type="registration"
          handleFormSubmit={handleAuthFormSubmit}
          isSubmitting={registrationFetchStatus === 'pending'}
          hasBackendError={registrationFetchStatus === 'rejected'}
        />
      );
    case 'email-confirm':
      return (
        <OtpForm
          title="E-mail confirmation"
          description="To confirm your e-mail, enter the code from the message that we sent you by e-mail"
          email={getEmail()}
          handleFormSubmit={handleOtpFormSubmit}
          handleResendCode={handleResendOtp}
          handleBackClick={handleOtpBackClick}
          isSubmitting={emailVerifyFetchStatus === 'pending'}
          hasBackendError={emailVerifyFetchStatus === 'rejected'}
        />
      );
    case 'success':
      return <AuthSuccess variant="registration" handleContinue={handleSuccessContinue} />;
  }
};
