import { useDispatch } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { affiliateReducer } from './affiliate-reducer/affiliate.reducer';
import { assetsReducer } from './assets-reducer/assets.reducer';
import { authReducer } from './auth-reducer/auth.reducer';
import { dashboardReducer } from './dashboard-reducer/dashboard.reducer';
import { depositReducer } from './deposit-reducer/deposit.reducer';
import { invoicePdfLinksReducer } from './Invoice-pdf-links-reducer/Invoice-pdf-links.reducer';
import { invoiceReducer } from './invoice-reducer/invoice.reducer';
import { ipReducer } from './ip-reducer/ip.reducer';
import { modalsReducer } from './modals-reducer/modals.reducer';
import { paymentReducer } from './payment-reducer/payment.reducer';
import { profileReducer } from './profile-reducer/profile.reducer';
import { purchaseReducer } from './purchase-reducer/purchase.reducer';
import { ratesReducer } from './rates-reducer/rates.reducer';
import { replenishReducer } from './replenish-reducer/replenish.reducer';
import { transactionHistoryReducer } from './transaction-reducer/transaction-history.reducer';
import { verificationReducer } from './verification-reducer/verification.reducer';
import { withdrawReducer } from './withdraw-reducer/withdraw.reducer';

const rootReducer = combineReducers({
  authReducer,
  dashboardReducer,
  assetsReducer,
  modalsReducer,
  transactionHistoryReducer,
  profileReducer,
  purchaseReducer,
  affiliateReducer,
  ratesReducer,
  depositReducer,
  replenishReducer,
  paymentReducer,
  invoiceReducer,
  withdrawReducer,
  ipReducer,
  invoicePdfLinksReducer,
  verificationReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type TRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export type TGetStateFn = () => TRootState;
