import React, { FC, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FormApi, ValidationErrors } from 'final-form';
import { IAuthFormValues } from 'types/ui/AuthForm/auth-form-values.interface';

import { Checkbox } from 'components/ui/Checkbox';
import { Input } from 'components/ui/Input';
import { removeSpacesAndEmojis } from 'utils/formatters/remove-spaces-and-emojis.util';
import {
  gtmClickEmailChange,
  gtmClickFormError,
  gtmClickPasswordChange,
} from 'utils/gtmSender/gtmSender';
import { AppRoute } from 'utils/route/app-route';
import { composeValidators } from 'utils/validators/compose-validators';
import {
  MAX_EMAIL_LENGTH,
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
} from 'utils/validators/constants/validation.constants';
import { isCheckboxRequired } from 'utils/validators/is-checkbox-required';
import { isNotTooLong } from 'utils/validators/is-not-too-long';
import { isNotTooShort } from 'utils/validators/is-not-too-short';
import { isRequired } from 'utils/validators/is-required';
import { isValidEmail } from 'utils/validators/is-valid-email';

import { CUSTOMER_AGREEMENT_URL } from './constants/customer-agreement-url.const';
import { IAuthFormProps } from './types/auth-form-props.interface';

import * as S from './AuthForm.styled';

export const AuthForm: FC<IAuthFormProps> = ({
  type,
  handleFormSubmit,
  handleForgotPassword,
  isSubmitting = false,
  hasBackendError = false,
}) => {
  const [emailValue, setEmailValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const handleEmailChange = (
    value: string,
    form: FormApi<IAuthFormValues, Partial<IAuthFormValues>>,
  ) => {
    form.change('email', removeSpacesAndEmojis(value));
    setEmailValue(value);
  };

  const handleEmailBlur = () => {
    if (emailValue) {
      gtmClickEmailChange(type === 'login' ? 'Log in' : 'Registration');
    }
  };

  const handlePasswordChange = (
    value: string,
    form: FormApi<IAuthFormValues, Partial<IAuthFormValues>>,
  ) => {
    form.change('password', removeSpacesAndEmojis(value));
    setPasswordValue(value);
  };

  const handlePasswordBlur = () => {
    if (passwordValue) {
      gtmClickPasswordChange(type === 'login' ? 'Log in' : 'Registration');
    }
  };

  const handleGtmErrors = (errors: ValidationErrors) => {
    if (typeof errors?.email === 'string') {
      gtmClickFormError('e-mail', errors.email);
    }
    if (typeof errors?.password === 'string') {
      gtmClickFormError('password', errors.password);
    }
  };

  return (
    <S.AuthSection>
      <S.MainContentWrapper>
        <S.Title>{type === 'login' ? 'Log in' : 'Registration'}</S.Title>

        <Form onSubmit={handleFormSubmit}>
          {({ handleSubmit, submitFailed, hasValidationErrors, form, errors }) => (
            <S.Form onSubmit={handleSubmit} $isFailed={submitFailed || hasBackendError}>
              <S.InputFieldsWrapper>
                <Field
                  name="email"
                  validate={composeValidators([
                    isRequired,
                    isValidEmail,
                    isNotTooLong(MAX_EMAIL_LENGTH, 'email'),
                  ])}
                >
                  {({ input, meta }) => (
                    <S.InputLabel>
                      <S.LabelText>E-mail</S.LabelText>
                      <Input
                        {...input}
                        type="text"
                        hasErrors={meta.error && submitFailed}
                        onChange={(evt) => handleEmailChange(evt.currentTarget.value, form)}
                        onBlur={handleEmailBlur}
                        hasValidationIcons={true}
                      />
                      {meta.error && submitFailed && (
                        <S.ErrorText>{meta.error}</S.ErrorText>
                      )}
                    </S.InputLabel>
                  )}
                </Field>

                <S.PasswordWrapper>
                  <Field
                    name="password"
                    validate={
                      type === 'login'
                        ? composeValidators([isRequired])
                        : composeValidators([
                            isRequired,
                            isNotTooShort(MIN_PASSWORD_LENGTH),
                            isNotTooLong(MAX_PASSWORD_LENGTH),
                          ])
                    }
                  >
                    {({ input, meta }) => (
                      <S.InputLabel>
                        <S.LabelText>Password</S.LabelText>
                        <Input
                          {...input}
                          type="password"
                          hasErrors={meta.error && submitFailed}
                          onChange={(evt) => handlePasswordChange(evt.currentTarget.value, form)}
                          onBlur={handlePasswordBlur}
                        />
                        {meta.error && submitFailed && (
                          <S.ErrorText>{meta.error}</S.ErrorText>
                        )}
                      </S.InputLabel>
                    )}
                  </Field>
                  {type === 'login' && (
                    <S.ForgotPasswordLink
                      linkTo={AppRoute.PasswordReset()}
                      text="I forgot password"
                      handleClick={handleForgotPassword}
                    />
                  )}
                </S.PasswordWrapper>

                {type === 'registration' && (
                  <Field
                    name="agreement"
                    type="checkbox"
                    validate={composeValidators([isCheckboxRequired])}
                  >
                    {({ input, meta }) => (
                      <S.CheckboxWrapper>
                        <Checkbox
                          {...input}
                          hasErrors={meta.error && submitFailed}
                          labelText="I agree to the"
                          linkText="Customer agreement"
                          linkTo={CUSTOMER_AGREEMENT_URL}
                          isOuterLink={true}
                        />
                        {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                      </S.CheckboxWrapper>
                    )}
                  </Field>
                )}
              </S.InputFieldsWrapper>

              <S.SubmitButton
                type="submit"
                text={type === 'login' ? 'Log in' : 'Create account'}
                disabled={(submitFailed && hasValidationErrors) || isSubmitting}
                onClick={() => handleGtmErrors(errors)}
              />
            </S.Form>
          )}
        </Form>

        <S.AuthFooter>
          <S.FooterText>
            {type === 'login' ? 'Not registered yet?' : 'Already registered?'}
          </S.FooterText>{' '}
          <S.FooterLink
            linkTo={type === 'login' ? AppRoute.Registration() : AppRoute.Login()}
            text={type === 'login' ? 'Registration →' : 'Sign in →'}
          />
        </S.AuthFooter>
      </S.MainContentWrapper>

      <S.FieldRequiredDescription>Required field</S.FieldRequiredDescription>
    </S.AuthSection>
  );
};
