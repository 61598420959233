import { SInfoArticle, SInfoBadge, SListTitle } from 'pages/PageDashboard/components/styled';
import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const MiningArticle = styled(SInfoArticle)`
  flex: 1 1 610px;
  gap: 16px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    flex-basis: 342px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    justify-content: flex-start;
    flex-basis: auto;
    padding: 12px;
    border-radius: 16px;
    background-color: #fff;
  }
`;

export const HashrateList = styled(SInfoBadge)`
  display: flex;
  flex: 1;
  gap: 8px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    padding: 12px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    gap: 14px;
    padding: 0;
    background-color: transparent;
  }
`;

export const HashrateTitle = styled(SListTitle)`
  grid-column: 2 / -1;
  grid-row: 1;
  max-width: 66px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    max-width: 100%;
  }
`;

export const HashrateAmountWrapper = styled.div`
  display: flex;
  flex: 1 1 75%;
  align-items: center;
  gap: 8px;
  border-radius: 20px;

  & > svg {
    width: 32px;
    height: 32px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    display: grid;
    flex: 1 1 50%;
    grid-template-columns: 24px max-content auto;
    grid-template-rows: auto auto;

    & > svg {
      grid-column: 1;
      grid-row: 1;
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    display: grid;
    grid-template-columns: 16px max-content auto;
    grid-template-rows: auto auto;
    row-gap: 4px;

    & > svg {
      grid-column: 1;
      grid-row: 1;
      width: 16px;
      height: 16px;
    }
  }
`;

export const HashrateAmountValue = styled(SText)`
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
  color: #000;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    font-size: 22px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    grid-column: 2;
    grid-row: 2;
    font-family: ${FontFamily.Montserrat};
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #000;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-column: 1 / 3;
    grid-row: 2;
    font-family: ${FontFamily.Montserrat};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #000;
    font-variant-numeric: lining-nums tabular-nums;
  }
`;

export const HashrateMinersCount = styled(SText)`
  display: flex;
  align-items: center;
  gap: 4px;
  width: min-content;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: #eaeaea;
  font-family: ${FontFamily.Montserrat};
  color: #000;

  & > svg {
    width: 16px;
    height: 16px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    grid-column: 3;
    grid-row: 2;
    width: min-content;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: ${FontFamily.Montserrat};
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.2px;

    & > svg {
      width: 12px;
      height: 12px;
    }
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-column: 3;
    grid-row: 2;
    border-radius: 8px;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;

    & > svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export const LaunchPendingWrapper = styled.div`
  display: grid;
  flex: 1 1 35%;
  gap: 0 8px;
  grid-template-columns: 32px max-content auto;
  grid-template-rows: auto auto;
  border-radius: 20px;

  & > svg {
    align-self: center;
    grid-column: 1;
    grid-row: 1 / -1;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    flex: 1 1 50%;
    grid-template-columns: 24px max-content auto;
    grid-template-rows: auto auto;

    & > svg {
      align-self: center;
      grid-column: 1;
      grid-row: 1;
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-template-columns: 16px max-content;
    grid-template-rows: auto auto;
    row-gap: 4px;

    & > svg {
      align-self: center;
      grid-column: 1;
      grid-row: 1;
      width: 16px;
      height: 16px;
    }
  }
`;

export const LaunchPendingTitle = styled(SListTitle)`
  align-self: end;
  grid-column: 2 / 4;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    align-self: start;
    grid-column: 2;
    max-width: 40px;
  }
`;

export const LaunchPendingHashrateValue = styled(SText)`
  align-self: center;
  grid-column: 2 / 3;
  grid-row: 2;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  white-space: nowrap;
  color: #000;

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-column: 1 / 3;
    font-variant-numeric: lining-nums tabular-nums;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
`;

export const LaunchPendingMinersCount = styled(SText)`
  display: flex;
  align-items: center;
  gap: 2px;
  grid-column: 3 / 4;
  grid-row: 2;
  width: min-content;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #eaeaea;
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #000;

  @media (min-width: ${BreakPoint.LaptopLow}) {
    background-color: transparent;
    justify-self: start;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    border-radius: 8px;
  }
`;

export const MiningList = styled(SInfoBadge)`
  display: flex;
  gap: 64px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    padding: 12px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    gap: 12px;
    padding: 0;
    background-color: transparent;
  }
`;

export const MiningItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    gap: 4px;
  }
`;

export const MiningTitle = styled(SText)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #646464;
  font-variant-numeric: lining-nums tabular-nums;

  & svg {
    flex-shrink: 0;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    align-items: flex-start;
    max-width: 88px;
  }
`;

export const MiningValue = styled(SText)`
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
  color: #000;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-variant-numeric: lining-nums tabular-nums;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
`;
