import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { ITransactionHistoryState } from './transaction-history.reducer';

export const getTransactionState = (state: TRootState): ITransactionHistoryState =>
  state.transactionHistoryReducer;

export const selectTransactionHistory = createSelector(
  [getTransactionState],
  (transactionHistoryState: ITransactionHistoryState) => transactionHistoryState.transactions,
);

export const selectTransactionHistoryStatus = createSelector(
  [getTransactionState],
  (transactionHistoryState: ITransactionHistoryState) => transactionHistoryState.status,
);

export const selectTransactionHistoryError = createSelector(
  [getTransactionState],
  (transactionHistoryState: ITransactionHistoryState) => transactionHistoryState.error,
);
