import React from 'react';

import { SVisuallyHidden } from 'components/styled/SVisuallyHidden';

import * as S from './HistorySection.styled';

export const HistorySection: React.FC = () => {
  return (
    <S.Section>
      <SVisuallyHidden as="h2">Referral history</SVisuallyHidden>
      <S.Notification>This section is under construction</S.Notification>
      <S.Text>
        Referral history and new features coming soon.
        <br />
        For now you can use your affiliate link to earn rewards.
      </S.Text>
    </S.Section>
  );
};
