import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { WithdrawForm } from 'pages/PageDashboard/components/ui/WithdrawForm';
import { IWithdrawBtcFormValues } from 'pages/PageDashboard/components/ui/WithdrawForm/types/withdraw-btc-form-values.interface';
import {
  HISTORY_TRANSACTIONS_COUNT,
  INITIAL_DATE,
} from 'pages/PageTransactionsHistory/constants/constants';
import { VerificationPanel } from 'pages/PageVerification/components/ui/VerificationPanel';
import { LABEL_FOR_NOT_VERIFIED } from 'pages/PageVerification/constants/constants';
import { EVerificationStatus } from 'store/api/cuverse-api/profile/types/profile-response.interface';
import { selectDashboardWallet } from 'store/dashboard-reducer/dashboard.selectors';
import { requestDashboardWalletBalance } from 'store/dashboard-reducer/dashboard.thunk-actions';
import { selectProfile, selectWallets } from 'store/profile-reducer/profile.selectors';
import { selectBtcUsdRate } from 'store/rates-reducer/rates.selectors';
import { useAppDispatch } from 'store/store';
import { requestTransactionHistoryData } from 'store/transaction-reducer/transaction-history.thunk-actions';
import { resetWithdrawConfirmationStatus } from 'store/withdraw-reducer/withdraw.reducer';
import {
  selectWithdrawConfirmationStatus,
  selectWithdrawStep,
} from 'store/withdraw-reducer/withdraw.selectors';
import {
  confirmWithdrawThunkAction,
  requestWithdrawThunkAction,
} from 'store/withdraw-reducer/withdraw.thunk-actions';
import { IOtpFormValues } from 'types/ui/OtpForm/otp-form-values.interface';

import { OtpForm } from 'components/ui/OtpForm';
import { getIsBasicVerificationStatus } from 'utils/common/get-is-basic-verification-status.util';
import { getIsNotVerifiedStatus } from 'utils/common/get-is-not-verified-status.util';
import { getVerificationPanelText } from 'utils/common/get-verification-panel-text.util';
import { setEmailSendTime } from 'utils/storage/date-and-time/set-email-send-time.util';

import * as S from './WithdrawContent.styled';

export const WithdrawContent: React.FC = () => {
  const dispatch = useAppDispatch();

  const { amount: btcAmountInWallet } = useSelector(selectDashboardWallet);
  const { btc: btcWalletAddress } = useSelector(selectWallets);
  const btcUsdRate = useSelector(selectBtcUsdRate);
  const confirmationStatus = useSelector(selectWithdrawConfirmationStatus);
  const { email, verificationStatus } = useSelector(selectProfile);
  const withdrawStep = useSelector(selectWithdrawStep);
  const isNotVerified = getIsNotVerifiedStatus(verificationStatus);
  const verificationInfo = getVerificationPanelText(!isNotVerified);

  const [withdrawFormValues, setWithdrawFormValues] = useState<IWithdrawBtcFormValues | null>(null);

  const handleWithdrawFormSubmit = (values: IWithdrawBtcFormValues) => {
    setWithdrawFormValues(values);
    setEmailSendTime();
    void dispatch(
      requestWithdrawThunkAction({
        body: {
          amount: Number(values.amount),
          coin: 'BTC',
        },
        onSuccessCb: () => {
          const updateDashboardInfo = () => {
            void dispatch(
              requestTransactionHistoryData({
                count: HISTORY_TRANSACTIONS_COUNT,
                dateStart: INITIAL_DATE,
              }),
            );
            void dispatch(requestDashboardWalletBalance());
          };
          updateDashboardInfo();
        },
      }),
    );
  };

  const handleOtpFormSubmit = ({ code }: IOtpFormValues) => {
    void dispatch(confirmWithdrawThunkAction({ link: code }));
  };

  const handleResendOtp = () => {
    if (withdrawFormValues) {
      handleWithdrawFormSubmit(withdrawFormValues);
    }
  };

  useEffect(() => {
    dispatch(resetWithdrawConfirmationStatus());
  }, [dispatch]);

  if (isNotVerified) {
    return (
      <VerificationPanel label={LABEL_FOR_NOT_VERIFIED} data={verificationInfo} isWithdrawalView />
    );
  }

  return (
    <S.Wrapper>
      {withdrawStep === 'initial' ? (
        <WithdrawForm
          btcAmountInWallet={btcAmountInWallet}
          btcUsdRate={btcUsdRate}
          savedWallet={btcWalletAddress}
          handleFormSubmit={handleWithdrawFormSubmit}
          isBasicVerification={getIsBasicVerificationStatus(verificationStatus)}
        />
      ) : (
        <OtpForm
          title=""
          description="To confirm withdrawal enter the code from the message we sent you by e-mail"
          codeType="withdraw"
          email={email}
          handleFormSubmit={handleOtpFormSubmit}
          handleResendCode={handleResendOtp}
          isSubmitting={confirmationStatus !== 'initial' && confirmationStatus !== 'rejected'}
          hasBackendError={confirmationStatus === 'rejected'}
        />
      )}
    </S.Wrapper>
  );
};
