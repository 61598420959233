import React from 'react';

import { AssetsSection } from './components/ui/AssetsSection';

import * as S from './PageAssets.styled';

export const PageAssets: React.FC = () => {
  return (
    <>
      <S.ContentHeader>
        <S.ContentTitle>Assets</S.ContentTitle>
      </S.ContentHeader>
      <AssetsSection />
    </>
  );
};
