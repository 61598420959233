import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMinersDataMapped } from 'store/api/cuverse-api/assets/types/assets-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { requestAssetsData } from './assets.thunk-actions';

export interface IAssetsState {
  assets: IMinersDataMapped[];
  status: TFetchStatus;
  error: string | null;
}

export const initialState: IAssetsState = {
  assets: [],
  status: 'initial',
  error: null,
};

const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(requestAssetsData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      requestAssetsData.fulfilled,
      (state, action: PayloadAction<IMinersDataMapped[]>) => {
        state.status = 'fulfilled';
        state.assets = action.payload;
      },
    );
    builder.addCase(requestAssetsData.rejected, (state, error) => {
      state.status = 'rejected';
      state.error = JSON.stringify(error);
    });
  },
});

export const assetsReducer = assetsSlice.reducer;
