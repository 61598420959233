import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IAssetsState } from './assets.reducer';

export const getAssetsState = (state: TRootState): IAssetsState => state.assetsReducer;

export const selectAssets = createSelector([getAssetsState], (assetsdState) => assetsdState.assets);

export const selectAssetsStatus = createSelector(
  [getAssetsState],
  (assetsdState) => assetsdState.status,
);

export const selectAssetsError = createSelector(
  [getAssetsState],
  (assetsdState) => assetsdState.error,
);
