import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { TRegistrationStep } from 'pages/PageRegistration/types/registration-step.type';
import { selectUserAuthStatus } from 'store/auth-reducer/auth.selectors';

export const useRegistrationStep = (
  registrationStep: TRegistrationStep,
  setRegistrationStep: Dispatch<SetStateAction<TRegistrationStep>>,
): void => {
  const userAuthStatus = useSelector(selectUserAuthStatus);

  switch (userAuthStatus) {
    case 'email-verification-pending':
      registrationStep !== 'email-confirm' && setRegistrationStep('email-confirm');
      break;
    case 'registration-success':
      registrationStep !== 'success' && setRegistrationStep('success');
      break;
    case 'anonymous':
    case 'unauthorized':
    case 'authorized-verified':
      registrationStep !== 'initial' && setRegistrationStep('initial');
      break;
  }
};
