import { EVerificationStatus } from 'store/api/cuverse-api/profile/types/profile-response.interface';

/**
 * basic verified status
 * @param verificationStatus status of verification
 * @returns boolean value
 */
export const getIsBasicVerificationStatus = (verificationStatus: EVerificationStatus): boolean => {
  return (
    verificationStatus === EVerificationStatus.Verified ||
    verificationStatus === EVerificationStatus.FinalVerificationChecking
  );
};
