import { createSlice } from '@reduxjs/toolkit';
import { TPasswordResetStep } from 'pages/PagePasswordReset/types/password-reset-step.type';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { TUserAuthStatus } from 'types/user/user-auth-status.type';

import {
  requestConfirm2FAThunkAction,
  requestDisable2FAThunkAction,
  requestEmailResendThunkAction,
  requestEmailVerifyThunkAction,
  requestEnable2FAThunkAction,
  requestLoginThunkAction,
  requestLogoutThunkAction,
  requestOtpVerificationThunkAction,
  requestPasswordChangeCodeThunkAction,
  requestPasswordResetThunkAction,
  requestRegisterThunkAction,
  requestTokenProlongationThunkAction,
} from './auth.thunk-actions';

export interface IUserState {
  debugError: string | null;
  userAuthStatus: TUserAuthStatus;
  loginFetchStatus: TFetchStatus;
  logoutFetchStatus: TFetchStatus;
  registrationFetchStatus: TFetchStatus;
  emailVerifyFetchStatus: TFetchStatus;
  emailResendFetchStatus: TFetchStatus;
  passwordChangeStep: TPasswordResetStep;
  passwordChangeCodeFetchStatus: TFetchStatus;
  otpVerificationFetchStatus: TFetchStatus;
  passwordResetStatus: TFetchStatus;
  enable2FAFetchStatus: TFetchStatus;
  confirm2FAFetchStatus: TFetchStatus;
  disable2FAFetchStatus: TFetchStatus;
  qrCode2FAUrl: string | null;
}

const initialState: IUserState = {
  debugError: null,
  userAuthStatus: 'anonymous',
  loginFetchStatus: 'initial',
  logoutFetchStatus: 'initial',
  registrationFetchStatus: 'initial',
  emailVerifyFetchStatus: 'initial',
  emailResendFetchStatus: 'initial',
  passwordChangeStep: 'request-code',
  passwordChangeCodeFetchStatus: 'initial',
  otpVerificationFetchStatus: 'initial',
  passwordResetStatus: 'initial',
  enable2FAFetchStatus: 'initial',
  confirm2FAFetchStatus: 'initial',
  disable2FAFetchStatus: 'initial',
  qrCode2FAUrl: null,
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setUserAuthStatusAction: (state, { payload }: { payload: TUserAuthStatus }) => {
      state.userAuthStatus = payload;
    },
    setPasswordChangeStepAction: (state, { payload }: { payload: TPasswordResetStep }) => {
      state.passwordChangeStep = payload;
    },
    resetPasswordChangeStepAction: (state) => {
      state.passwordChangeStep = 'request-code';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestLoginThunkAction.pending, (state) => {
      state.loginFetchStatus = 'pending';
      state.debugError = null;
    });
    builder.addCase(requestLoginThunkAction.fulfilled, (state) => {
      state.loginFetchStatus = 'fulfilled';
    });
    builder.addCase(requestLoginThunkAction.rejected, (state, error) => {
      state.loginFetchStatus = 'rejected';
      state.debugError = JSON.stringify(error);
    });
    builder.addCase(requestLogoutThunkAction.pending, (state) => {
      state.logoutFetchStatus = 'pending';
      state.debugError = null;
    });
    builder.addCase(requestLogoutThunkAction.fulfilled, (state) => {
      state.userAuthStatus = 'unauthorized';
      state.logoutFetchStatus = 'fulfilled';
    });
    builder.addCase(requestLogoutThunkAction.rejected, (state) => {
      state.logoutFetchStatus = 'rejected';
    });
    builder.addCase(
      requestTokenProlongationThunkAction.fulfilled,
      (state, { payload: isUserVerified }) => {
        if (isUserVerified) {
          state.userAuthStatus = 'authorized-verified';
        } else {
          state.userAuthStatus = 'email-verification-pending';
        }
      },
    );
    builder.addCase(requestTokenProlongationThunkAction.rejected, (state, error) => {
      state.userAuthStatus = 'unauthorized';
      state.debugError = JSON.stringify(error);
    });
    builder.addCase(requestRegisterThunkAction.pending, (state) => {
      state.registrationFetchStatus = 'pending';
      state.debugError = null;
    });
    builder.addCase(requestRegisterThunkAction.fulfilled, (state) => {
      state.registrationFetchStatus = 'fulfilled';
    });
    builder.addCase(requestRegisterThunkAction.rejected, (state, error) => {
      state.registrationFetchStatus = 'rejected';
      state.debugError = JSON.stringify(error);
    });
    builder.addCase(requestEmailVerifyThunkAction.pending, (state) => {
      state.emailVerifyFetchStatus = 'pending';
      state.debugError = null;
    });
    builder.addCase(requestEmailVerifyThunkAction.fulfilled, (state) => {
      state.emailVerifyFetchStatus = 'fulfilled';
      state.userAuthStatus = 'registration-success';
    });
    builder.addCase(requestEmailVerifyThunkAction.rejected, (state, error) => {
      state.emailVerifyFetchStatus = 'rejected';
      state.debugError = JSON.stringify(error);
    });
    builder.addCase(requestEmailResendThunkAction.pending, (state) => {
      state.emailResendFetchStatus = 'pending';
      state.debugError = null;
    });
    builder.addCase(requestEmailResendThunkAction.fulfilled, (state) => {
      state.emailResendFetchStatus = 'fulfilled';
    });
    builder.addCase(requestEmailResendThunkAction.rejected, (state, error) => {
      state.emailResendFetchStatus = 'rejected';
      state.debugError = JSON.stringify(error);
    });
    builder.addCase(requestPasswordChangeCodeThunkAction.pending, (state) => {
      state.passwordChangeCodeFetchStatus = 'pending';
      state.debugError = null;
    });
    builder.addCase(requestPasswordChangeCodeThunkAction.fulfilled, (state) => {
      state.passwordChangeCodeFetchStatus = 'fulfilled';
    });
    builder.addCase(requestPasswordChangeCodeThunkAction.rejected, (state, error) => {
      state.passwordChangeCodeFetchStatus = 'rejected';
      state.debugError = JSON.stringify(error);
    });
    builder.addCase(requestOtpVerificationThunkAction.pending, (state) => {
      state.otpVerificationFetchStatus = 'pending';
      state.debugError = null;
    });
    builder.addCase(requestOtpVerificationThunkAction.fulfilled, (state) => {
      state.otpVerificationFetchStatus = 'fulfilled';
    });
    builder.addCase(requestOtpVerificationThunkAction.rejected, (state, error) => {
      state.otpVerificationFetchStatus = 'rejected';
      state.debugError = JSON.stringify(error);
    });
    builder.addCase(requestPasswordResetThunkAction.pending, (state) => {
      state.passwordResetStatus = 'pending';
      state.debugError = null;
    });
    builder.addCase(requestPasswordResetThunkAction.fulfilled, (state) => {
      state.passwordResetStatus = 'fulfilled';
    });
    builder.addCase(requestPasswordResetThunkAction.rejected, (state, error) => {
      state.passwordResetStatus = 'rejected';
      state.debugError = JSON.stringify(error);
    });
    builder.addCase(requestEnable2FAThunkAction.pending, (state) => {
      state.enable2FAFetchStatus = 'pending';
      state.qrCode2FAUrl = null;
      state.debugError = null;
    });
    builder.addCase(requestEnable2FAThunkAction.fulfilled, (state, action) => {
      state.enable2FAFetchStatus = 'fulfilled';
      state.qrCode2FAUrl = action.payload;
    });
    builder.addCase(requestEnable2FAThunkAction.rejected, (state, error) => {
      state.enable2FAFetchStatus = 'rejected';
      state.qrCode2FAUrl = null;
      state.debugError = JSON.stringify(error);
    });
    builder.addCase(requestConfirm2FAThunkAction.pending, (state) => {
      state.confirm2FAFetchStatus = 'pending';
      state.debugError = null;
    });
    builder.addCase(requestConfirm2FAThunkAction.fulfilled, (state) => {
      state.confirm2FAFetchStatus = 'fulfilled';
    });
    builder.addCase(requestConfirm2FAThunkAction.rejected, (state, error) => {
      state.confirm2FAFetchStatus = 'rejected';
      state.debugError = JSON.stringify(error);
    });
    builder.addCase(requestDisable2FAThunkAction.pending, (state) => {
      state.disable2FAFetchStatus = 'pending';
      state.debugError = null;
    });
    builder.addCase(requestDisable2FAThunkAction.fulfilled, (state) => {
      state.disable2FAFetchStatus = 'fulfilled';
    });
    builder.addCase(requestDisable2FAThunkAction.rejected, (state, error) => {
      state.disable2FAFetchStatus = 'rejected';
      state.debugError = JSON.stringify(error);
    });
  },
});

export const {
  setUserAuthStatusAction,
  setPasswordChangeStepAction,
  resetPasswordChangeStepAction,
} = authSlice.actions;
export const authReducer = authSlice.reducer;
