import React from 'react';
import { Field, Form } from 'react-final-form';
import { ReactComponent as InvoiceIcon } from 'assets/icons/purchase-page-icons/invoice.svg';
import { IPaymentAgreementFormValue } from 'pages/PagePurchase/types/payment-agreement-form.interface';

import { Checkbox } from 'components/ui/Checkbox';
import { composeValidators } from 'utils/validators/compose-validators';
import { isCheckboxRequired } from 'utils/validators/is-checkbox-required';

import * as S from './PaymentAgreementForm.styled';

interface IPaymentAgreementFormProps {
  handlePaymentSubmit: (value: IPaymentAgreementFormValue) => void;
  handleAdditionalTermsClick: () => void;
  isSubmitting?: boolean;
}

export const PaymentAgreementForm: React.FC<IPaymentAgreementFormProps> = ({
  handlePaymentSubmit,
  handleAdditionalTermsClick,
  isSubmitting,
}) => {
  return (
    <Form onSubmit={handlePaymentSubmit}>
      {({ handleSubmit, submitFailed, hasValidationErrors }) => (
        <S.PaymentForm onSubmit={handleSubmit}>
          <Field
            name="agreement"
            type="checkbox"
            validate={composeValidators([isCheckboxRequired])}
          >
            {({ input, meta }) => (
              <S.CheckboxAgreementWrapper>
                <Checkbox
                  {...input}
                  hasErrors={meta.error && submitFailed}
                  labelText="I have read and agree to the"
                  linkText="Additional Terms of use for Service"
                  handleLinkClick={handleAdditionalTermsClick}
                />
                {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
              </S.CheckboxAgreementWrapper>
            )}
          </Field>
          <S.PayButton
            type="submit"
            text="Pay"
            icon={<InvoiceIcon />}
            disabled={(submitFailed && hasValidationErrors) || isSubmitting}
          />
        </S.PaymentForm>
      )}
    </Form>
  );
};
