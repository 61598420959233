export const TERMS_TITLE = 'Hong Kong 2024';
export const TERMS_DESCRIPTION =
  'These Terms and Conditions are an integral part of the Cuverse Terms and define the procedure, conditions, place, and terms of the "20% Cashback on Electricity Fee with Miner Purchase" promotion (hereinafter referred to as the "Promotion").';

// Name of the Promotion
export const PROMOTION_NAME_TITLE = 'Name of the Promotion';
export const PROMOTION_NAME_TERM =
  'Name of the Promotion: "20% Cashback on Electricity Fee with Miner Purchase".';

// Territory of the Promotion
export const PROMOTION_TERRITORY_TITLE = 'Territory of the Promotion';
export const PROMOTION_TERRITORY_TERM =
  'Territory of the Promotion: all regions where Cuverse is present.';

// Terms and Definitions
export const TERMS_DEFINITIONS_TITLE = 'Terms and Definitions';
export const PROMOTION_TERM = 'Promotion';
export const PROMOTION_DESCRIPTION =
  'a marketing event by Cuverse aimed at increasing customer activity in purchasing services and enhancing participant loyalty to the Cuverse Loyalty Program.';
export const CASHBACK_TERM = 'Cashback';
export const CASHBACK_DESCRIPTION =
  "a conditional unit credited by Cuverse to the Participant's account for fulfilling the Promotion conditions, used exclusively for paying electricity fees. Cashback is not and cannot be a means of payment, any type of currency, or security, and cannot be cashed out, transferred, gifted to third parties, or inherited.";
export const COMPANY_TERM = 'Company';
export const COMPANY_DESCRIPTION =
  'Cuverse, registered in Hong Kong, with a registered address at 7/F, MW Tower, 111 Bonham Strand, Sheung Wan, Hong Kong.';
export const PARTICIPANT_TERM = 'Participant of the Promotion';
export const PARTICIPANT_DESCRIPTION =
  'an individual or legal entity who is at least 18 years old and fully competent, purchasing a miner during the Promotion period and agreeing to the terms of these Terms and Conditions.';
export const PROMOTION_PERIOD_TERM = 'Promotion Period';
export const PROMOTION_PERIOD_DESCRIPTION = 'from July 1 to September 1, 2024, inclusive.';

// General Provisions
export const GENERAL_TITLE = 'General Provisions';
export const GENERAL_1_TERM =
  'These Terms and Conditions define the conditions and procedure for conducting the Promotion and for customer participation in the Promotion. The Terms are a public offer and are posted on the official Cuverse website.';
export const GENERAL_2_TERM =
  "Participation in the Promotion implies the Participant's full agreement with these Terms and Conditions.";

// Promotion Conditions
export const CONDITIONS_TITLE = 'Promotion Conditions';
export const CONDITIONS_1_TERM =
  'Within the Promotion, the Participant, by purchasing a miner on the official Cuverse website from July 1 to September 1, 2024, gains the right to receive a 20% cashback on electricity fee payments.';
export const CONDITIONS_2_TERM =
  'Cashback is credited on the 15th of each month following the purchase.';
export const CONDITIONS_3_TERM =
  'Cashback is provided for one calendar year from the date of the miner purchase and is valid only for electricity fees accrued during this period.';

// Participation Procedure
export const PARTICIPATION_TITLE = 'Participation Procedure';
export const PARTICIPATION_1_TERM =
  'To participate in the Promotion, the Participant must purchase a miner on the official Cuverse website from July 1 to September 1, 2024.';
export const PARTICIPATION_2_TERM =
  'After completing the miner purchase on the website, the Participant automatically becomes a participant in the Promotion and gains the right to cashback according to these Terms and Conditions.';
export const PARTICIPATION_3_TERM =
  'Participation in the Promotion does not require additional applications or registrations.';

// Procedure for Crediting and Using Cashback
export const CREDITING_TITLE = 'Procedure for Crediting and Using Cashback';
export const CREDITING_1_TERM =
  "Cashback of 20% is credited to the Participant's account on the 15th of each month following the miner purchase.";
export const CREDITING_2_TERM =
  'Credited cashback can be used by the Participant exclusively for paying electricity fees.';
export const CREDITING_3_TERM =
  'Cashback is non-refundable and cannot be converted into cash. Unused cashback is canceled after one calendar year from the date of the miner purchase.';
export const CREDITING_4_TERM =
  'In case of contract cancellation, previously credited cashback is canceled.';

// Responsibility of the Parties
export const RESPONSIBILITY_TITLE = 'Responsibility of the Parties';
export const RESPONSIBILITY_1_TERM =
  'The Company is not responsible for any losses incurred by the Participant as a result of using or being unable to use the provided cashback.';
export const RESPONSIBILITY_2_TERM =
  'The Participant is fully responsible for complying with these Terms and Conditions and for using the provided cashback.';

// Confidentiality
export const CONFIDENTIALITY_TITLE = 'Confidentiality';
export const CONFIDENTIALITY_TERM =
  "The Company undertakes not to disclose the Participant's personal data to third parties, except as required by applicable law.";

// Other Conditions
export const OTHER_TITLE = 'Other Conditions';
export const OTHER_1_TERM =
  'The Company reserves the right to make changes to the Promotion conditions and these Terms and Conditions by notifying the Participants by publishing the changes on the official Cuverse website no later than 7 calendar days before the changes come into force.';
export const OTHER_2_TERM =
  "Participation in the Promotion implies the Participant's full agreement with these Terms and Conditions.";
export const OTHER_3_TERM =
  "All disputes arising in connection with the Promotion shall be resolved through negotiations. If an agreement is not reached, the dispute shall be referred to the court at the Company's location in accordance with applicable law.";
export const OTHER_4_TERM =
  'The Participant is independently responsible for fulfilling their tax obligations, including the payment of all applicable taxes and other mandatory payments in respect of income received under the Promotion.';
