import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IModalsState } from './modals.reducer';

const getMenusState = (state: TRootState): IModalsState => state.modalsReducer;

export const selectIsEnable2FAPopupOpened = createSelector(
  [getMenusState],
  ({ popupMode }) => popupMode === 'enable-2fa',
);

export const selectIsDepositBtcPopupOpened = createSelector(
  [getMenusState],
  ({ popupMode }) => popupMode === 'deposit-btc',
);

export const selectIsWithdrawBtcPopupOpened = createSelector(
  [getMenusState],
  ({ popupMode }) => popupMode === 'withdraw-btc',
);

export const selectIsDepositElectricityPopupOpened = createSelector(
  [getMenusState],
  ({ popupMode }) => popupMode === 'deposit-electricity',
);

export const selectIsChangeWalletPopupOpened = createSelector(
  [getMenusState],
  ({ popupMode }) => popupMode === 'change-wallet',
);

export const selectIsAdditionalTermsPopupOpened = createSelector(
  [getMenusState],
  ({ popupMode }) => popupMode === 'additional-terms',
);

export const selectIsElectricityCashbackTermsPopupOpened = createSelector(
  [getMenusState],
  ({ popupMode }) => popupMode === 'electricity-cashback-terms',
);
