import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IOrdersIds,
  IPaymentResponseData,
} from 'store/api/cuverse-api/payment/types/payment-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { fetchPaymentThunkAction } from './payment.thunk-actions';

export interface IPaymentState {
  paymentStatusResult: string | null;
  ordersIds: IOrdersIds[] | null;
  status: TFetchStatus;
  error: string | null;
}

export const initialState: IPaymentState = {
  paymentStatusResult: null,
  ordersIds: null,
  status: 'initial',
  error: null,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentThunkAction.pending, (state) => {
      state.status = 'pending';
      state.error = null;
    });
    builder.addCase(
      fetchPaymentThunkAction.fulfilled,
      (state, action: PayloadAction<IPaymentResponseData>) => {
        state.status = 'fulfilled';
        state.paymentStatusResult = action.payload.status;
        state.ordersIds = action.payload.data;
      },
    );
    builder.addCase(fetchPaymentThunkAction.rejected, (state, error) => {
      state.status = 'rejected';
      state.error = JSON.stringify(error);
    });
  },
});

export const paymentReducer = paymentSlice.reducer;
