import { cuverseApi } from 'store/api/cuverse-api/cuverse.api';

import { IPaymentRequestBody } from './types/payment-request-body.interface';
import { IPaymentResponse } from './types/payment-response.interface';
import { PaymentRoute } from './payment.route';

export const requestPayment = async (body: IPaymentRequestBody): Promise<IPaymentResponse> =>
  cuverseApi.post(PaymentRoute.Payment(), body).then((res) => {
    return res?.data;
  });
