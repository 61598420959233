import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  gap: 12px;
  margin: 32px 26px;
  border-radius: 16px;
  background-color: #e5eaf1;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    margin: 32px 24px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    margin: 32px 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin: 24px 16px 32px 24px;
    padding: 24px;
  }
`;

export const Notification = styled(SText)`
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
  text-align: center;
  color: #0e46c0;
`;

export const Text = styled(SText)`
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #0e46c0;
`;
