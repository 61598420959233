import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { useCloseByClick } from 'hooks/useCloseByClick';
import { useCloseByEsc } from 'hooks/useCloseByEsc';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { DepositBtcContent } from 'pages/PageDashboard/components/logic/DepositBtcContent';
import { DepositElectricityForm } from 'pages/PageDashboard/components/logic/DepositElectricityForm';
import { WithdrawContent } from 'pages/PageDashboard/components/logic/WithdrawContent';
import { useChangeWalletPopupAttributes } from 'pages/PageDashboard/hooks/useChangeWalletPopupAttributes';
import { useWithdrawPopupTitle } from 'pages/PageDashboard/hooks/useWithdrawPopupTitle';
import { Enable2FAContent } from 'pages/PageProfile/components/logic/Enable2FAContent';
import { AdditionalTerms } from 'pages/PagePurchase/components/logic/AdditionalTerms';
import { ElectricityCashbackTerms } from 'pages/PagePurchase/components/ui/ElectricityCashbackTerms/ElectricityCashbackTerms';
import { selectLogoutFetchStatus } from 'store/auth-reducer/auth.selectors';
import { requestLogoutThunkAction } from 'store/auth-reducer/auth.thunk-actions';
import { setDepositStepAction } from 'store/deposit-reducer/deposit.reducer';
import { closePopupAction } from 'store/modals-reducer/modals.reducer';
import {
  selectIsAdditionalTermsPopupOpened,
  selectIsChangeWalletPopupOpened,
  selectIsDepositBtcPopupOpened,
  selectIsDepositElectricityPopupOpened,
  selectIsElectricityCashbackTermsPopupOpened,
  selectIsEnable2FAPopupOpened,
  selectIsWithdrawBtcPopupOpened,
} from 'store/modals-reducer/modals.selectors';
import { setWalletChangeStatusAction } from 'store/profile-reducer/profile.reducer';
import { setPurchaseStepAction } from 'store/purchase-reducer/purchase.reducer';
import { selectPurchaseStep } from 'store/purchase-reducer/purchase.selectors';
import { useAppDispatch } from 'store/store';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { Popup } from 'components/ui/Popup';

import { InnerHeader } from './components/ui/InnerHeader';
import { MainMenu } from './components/ui/MainMenu';

import * as S from './InnerLayout.styled';

export const InnerLayout: React.FC = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const purchaseStep = useSelector(selectPurchaseStep);
  const logoutFetchStatus = useSelector(selectLogoutFetchStatus);
  const isEnable2FAPopupOpened = useSelector(selectIsEnable2FAPopupOpened);
  const isDepositBtcPopupOpened = useSelector(selectIsDepositBtcPopupOpened);
  const isWithdrawBtcPopupOpened = useSelector(selectIsWithdrawBtcPopupOpened);
  const isDepositElectricityPopupOpened = useSelector(selectIsDepositElectricityPopupOpened);
  const isChangeWalletPopupOpened = useSelector(selectIsChangeWalletPopupOpened);
  const isAdditionalTermsPopupOpened = useSelector(selectIsAdditionalTermsPopupOpened);
  const isElectricityCashbackTermsPopupOpened = useSelector(
    selectIsElectricityCashbackTermsPopupOpened,
  );
  const { innerWidth, innerWidthWithoutScrollBar } = useInnerWidth();
  const sideSpace = (innerWidthWithoutScrollBar - parseInt(BreakPoint.DesktopTop)) / 2;

  const [isMainMenuOpened, setIsMainMenuOpened] = useState(false);

  const { withdrawPopupTitle } = useWithdrawPopupTitle();
  const { walletAddressPopupTitle, walletAddressPopupContent } = useChangeWalletPopupAttributes();

  const handleMainMenuOpen = (): void => {
    setIsMainMenuOpened(true);
  };

  const handleMainMenuClose = (): void => {
    setIsMainMenuOpened(false);
  };

  const handleLogout = (): void => {
    void dispatch(requestLogoutThunkAction());
  };

  const handleEnable2FAPopupClose = (): void => {
    dispatch(closePopupAction());
  };

  const handleDepositBtcPopupClose = (): void => {
    dispatch(setDepositStepAction('initial'));
    dispatch(closePopupAction());
  };

  const handleWithdrawBtcPopupClose = (): void => {
    dispatch(closePopupAction());
  };

  const handleDepositElectricityPopupClose = (): void => {
    dispatch(closePopupAction());
  };

  const handleChangeWalletPopupClose = (): void => {
    dispatch(setWalletChangeStatusAction('not-confirmed'));
    dispatch(closePopupAction());
  };

  const handleAdditionalTermsPopupClose = (): void => {
    dispatch(closePopupAction());
  };

  const handleElectricityCashbackTermsPopupClose = (): void => {
    dispatch(closePopupAction());
  };

  const handleResetBuyingFlow = (): void => {
    if (purchaseStep !== 'buying') {
      dispatch(setPurchaseStepAction('buying'));
    }
  };

  useCloseByEsc({ isShown: isMainMenuOpened, cb: handleMainMenuClose });
  useCloseByClick({ isShown: isMainMenuOpened, cb: handleMainMenuClose });
  useScrollToTop(pathname);

  return (
    <S.Wrapper>
      <S.HeaderWrapper $sideSpace={sideSpace >= 0 ? sideSpace : 0}>
        <InnerHeader isMainMenuOpened={isMainMenuOpened} handleMainMenuOpen={handleMainMenuOpen} />
      </S.HeaderWrapper>
      {(innerWidth >= parseInt(BreakPoint.DesktopLow) || isMainMenuOpened) && (
        <S.MenuWrapper $sideSpace={sideSpace >= 0 ? sideSpace : 0}>
          <MainMenu
            handleLogout={handleLogout}
            isLoggingOut={logoutFetchStatus === 'pending'}
            handleResetBuyingFlow={handleResetBuyingFlow}
          />
        </S.MenuWrapper>
      )}

      <S.ContentWrapper $sideSpace={sideSpace >= 0 ? sideSpace : 0}>
        <Outlet />
      </S.ContentWrapper>

      {isEnable2FAPopupOpened && (
        <Popup
          title="Google Authenticator"
          content={<Enable2FAContent />}
          handlePopupClose={handleEnable2FAPopupClose}
        />
      )}
      {isDepositBtcPopupOpened && (
        <Popup
          title="Deposit BTC into your account"
          content={<DepositBtcContent />}
          handlePopupClose={handleDepositBtcPopupClose}
        />
      )}
      {isWithdrawBtcPopupOpened && (
        <Popup
          title={withdrawPopupTitle}
          content={<WithdrawContent />}
          handlePopupClose={handleWithdrawBtcPopupClose}
        />
      )}
      {isDepositElectricityPopupOpened && (
        <Popup
          title="Deposit electricity payment"
          content={<DepositElectricityForm />}
          handlePopupClose={handleDepositElectricityPopupClose}
        />
      )}
      {isChangeWalletPopupOpened && (
        <Popup
          title={walletAddressPopupTitle}
          content={walletAddressPopupContent}
          handlePopupClose={handleChangeWalletPopupClose}
        />
      )}
      {isAdditionalTermsPopupOpened && (
        <Popup
          title="Additional Terms of use for Service"
          content={<AdditionalTerms />}
          variant="wide"
          handlePopupClose={handleAdditionalTermsPopupClose}
        />
      )}
      {isElectricityCashbackTermsPopupOpened && (
        <Popup
          title='Terms and Conditions for the Promotion "20% Cashback on Electricity Fee with Miner Purchase" by Cuverse'
          content={<ElectricityCashbackTerms />}
          variant="wide"
          handlePopupClose={handleElectricityCashbackTermsPopupClose}
        />
      )}
    </S.Wrapper>
  );
};
