import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IdentificationCardIcon } from 'assets/icons/verify-icons/basic-verification-success-icon.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/verify-icons/check-icon.svg';
import { useInnerWidth } from 'hooks/useInnerWidth';
import {
  VERIFICATION_LABEL_FOR_WITHDRAWAL,
  verificationStatuses,
} from 'pages/PageVerification/constants/constants';
import { closePopupAction } from 'store/modals-reducer/modals.reducer';
import { selectIsWithdrawBtcPopupOpened } from 'store/modals-reducer/modals.selectors';
import { selectProfile } from 'store/profile-reducer/profile.selectors';
import { useAppDispatch } from 'store/store';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { getIsBasicVerificationStatus } from 'utils/common/get-is-basic-verification-status.util';
import { AppRoute } from 'utils/route/app-route';

import * as S from './VerificationInfo.styled';

interface IVerificationPanelDescription {
  text: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  description: string;
  buttonText: string;
}

interface IProps {
  data: IVerificationPanelDescription[];
  isWithdrawalView?: boolean;
  isLimitWithdrawalPanel?: boolean;
}

export const VerificationInfo: React.FC<IProps> = ({
  data,
  isWithdrawalView,
  isLimitWithdrawalPanel,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isWithdrawPopupOpen = useSelector(selectIsWithdrawBtcPopupOpened);
  const { verificationStatus } = useSelector(selectProfile);
  const isBasicVerification = getIsBasicVerificationStatus(verificationStatus);

  const { innerWidth } = useInnerWidth();

  const handleVerificationRedirect = () => {
    if (isWithdrawPopupOpen) {
      dispatch(closePopupAction());
    }
    navigate(AppRoute.Verification());
  };

  return (
    <S.VerificationInfoWrapper isWithdrawalView={isWithdrawalView}>
      {data.map((item) => {
        const IconComponent = item.Icon;
        const isDesabledButton =
          !isBasicVerification && item.text === verificationStatuses.fullVerification.statusText;
        const buttonVariant = isLimitWithdrawalPanel
          ? 'borderless'
          : isDesabledButton
            ? 'secondary'
            : 'primary';
        const icon = !isDesabledButton ? (
          <CheckIcon />
        ) : isDesabledButton &&
          innerWidth >= parseInt(BreakPoint.MobileTop) &&
          !isWithdrawalView ? (
          <IdentificationCardIcon />
        ) : undefined;
        return (
          <S.DescriptionContainer key={item.text} isWithdrawalView={isWithdrawalView}>
            {isLimitWithdrawalPanel ? (
              <S.Descirption>
                <div>
                  <IconComponent />
                </div>
                <S.DescriptionText>{VERIFICATION_LABEL_FOR_WITHDRAWAL}</S.DescriptionText>
              </S.Descirption>
            ) : (
              <>
                <S.Status>
                  <>
                    <IconComponent />
                    {item.text}
                  </>
                </S.Status>
                <S.Info>{item.description}</S.Info>
              </>
            )}
            <S.VerificationButton
              type="button"
              variant={buttonVariant}
              text={item.buttonText}
              icon={icon}
              onClick={handleVerificationRedirect}
              isFullWidth={isWithdrawalView || isDesabledButton}
              isLimitWithdrawalPanel={isLimitWithdrawalPanel}
              disabled={isDesabledButton}
            />
          </S.DescriptionContainer>
        );
      })}
    </S.VerificationInfoWrapper>
  );
};
