import { createAsyncThunk } from '@reduxjs/toolkit';
import { FallbackPaymentMessages } from 'constants/notification-messages/fallback-payment-messages';
import { requestPayment } from 'store/api/cuverse-api/payment/payment.service';
import {
  IPaymentResponse,
  IPaymentResponseData,
} from 'store/api/cuverse-api/payment/types/payment-response.interface';
import { fetchInvoicePdfLinksAction } from 'store/Invoice-pdf-links-reducer/Invoice-pdf-links.thunk-action';
import { setPurchaseStepAction } from 'store/purchase-reducer/purchase.reducer';

import { notifyError, notifySuccess } from 'utils/notify/notify.utils';

export const fetchPaymentThunkAction = createAsyncThunk(
  'payment/fetch',
  async (id: number, { dispatch }): Promise<IPaymentResponseData> => {
    return await requestPayment({
      purchase_id: id,
      payment_method: 'btc-balance',
      operation_type: 'pool_miner',
      language: 'en',
    })
      .then((response: IPaymentResponse) => {
        dispatch(setPurchaseStepAction('paid-success'));
        notifySuccess(FallbackPaymentMessages.SuccessfullyPayment);
        const orders = response.message.data?.map((order) => order.order_id);
        orders?.forEach((id) => dispatch(fetchInvoicePdfLinksAction(id)));
        return response.message;
      })
      .catch((error) => {
        notifyError(FallbackPaymentMessages.CannotPayment);
        throw error;
      });
  },
);
