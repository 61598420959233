import React, { useEffect } from 'react';
import { fetchPairRatesThunkAction } from 'store/rates-reducer/rates.thunk-actions';
import { useAppDispatch } from 'store/store';

import { HistorySection } from './components/logic/HistorySection';
import { InfoSection } from './components/logic/InfoSection';

export const PageDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    void dispatch(fetchPairRatesThunkAction());
  }, [dispatch]);
  
  return (
    <>
      <InfoSection />
      <HistorySection />
    </>
  );
};
